<template>
    <div class="edit-data">
        <div class="edit-nav">
            <i class="fas fa-angle-left" @click="btnout"></i>
            <span>编辑资料</span>
        </div>
        <editdatalist :userlist="userlist"></editdatalist>
    </div>
</template>

<script>
    import Editdatalist from "./Editdatalist";
    export default {
        name: "Editdata",
        components:{
          Editdatalist
        },
        data(){
            return{
                user_id:null,
                userlist:{}
            }
        },
        created() {
        },
        methods:{
            btnout(){
                this.$router.push("/user")
            },
        }
    }
</script>

<style scoped>
    .edit-data{
        position: relative;
        z-index: 10;
        width: 100%;
        height: 100vh;
        background-color: #fff;
    }
    .edit-nav{
        height: 39px;
        line-height: 39px;
        text-align: center;
        box-shadow: 0 0 5px rgba(50,50,50,.3);
        display: flex;
        align-items: center;
    }
    .edit-nav i{
        width: 10%;
        font-size: 20px;
    }
    .edit-nav span{
        flex: 1;
        margin-right: 8%;
        font-size: 14px;
    }

</style>