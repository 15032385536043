<template>
  <div>
    <div class="edit-nav-name" :style="{ width: $store.state.screenWidth + 'px' }">
      <i class="fas fa-angle-left" @click="btnoutjj"></i>
      <span>修改简介</span>
      <a @click="btneditUserInfo">保存</a>
    </div>
    <div class="edit-nav-input" :style="{ width: $store.state.screenWidth - 0 + 'px' }">
      <el-input
        type="textarea"
        v-model="my_info"
        maxlength="100"
        placeholder=""
        show-word-limit
        :autosize="{ minRows: 6, maxRows: 6 }"
        size="mini"
        :style="{ width: $store.state.screenWidth - 40 + 'px' }"
      >
      </el-input>
    </div>
  </div>
</template>

<script>
import { updateUserInfo } from 'network/user'
export default {
  name: 'Updatejj',
  props: {
    username: {
      type: String
    },
    my_info: {
      type: String
    }
  },
  methods: {
    btnoutjj() {
      this.$emit('btnoutjj')
    },
    btneditUserInfo() {
      updateUserInfo(this.my_info, this.username).then((res) => {
        console.log(res.state)
        this.$router.push('/usernote')
      })
    }
  }
}
</script>

<style scoped>
.edit-nav-name {
  width: 46%;
  height: 39px;
  box-shadow: 0 0 5px rgba(50, 50, 50, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
.edit-nav-name i {
  width: 10%;
  font-size: 20px;
}
.edit-nav-name span {
  font-size: 15px;
}
.edit-nav-name a {
  margin-right: 6%;
  background-color: #ff0000;
  padding: 7px 10px;
  border-radius: 10px;
  font-size: 12px;
  color: rgba(254, 254, 254, 0.9);
}
.edit-nav-input {
  margin: 20px 20px;
}
.edit-nav-input input {
  border: 0;
  background-color: rgba(220, 220, 220, 0.6);
  /*光标颜色*/
  caret-color: #1bc4fb;
  /*选中后*/
  outline: 0;
  color: #000;
  /*高度*/
  padding: 12px;
  width: 40%;
  border-radius: 12px;
  font-size: 13px;
}
.edit-nav-input input::placeholder {
  color: rgb(90, 90, 90);
  font-size: 14px;
}

/* 覆盖掉el组件中的背景色，添加圆角，去掉边框 */
.el-textarea /deep/ textarea.el-textarea__inner {
  height: 32px;
  background-color: rgba(220, 220, 220, 0.6);
  border: none;
  border-radius: 15px;
}

/* 覆盖掉el组件中的背景色 */
.el-textarea /deep/ span.el-input__count {
  background-color: rgba(220, 220, 220, 0.6);
}
</style>
