<template>
  <div class="edit-list">
    <div class="edit-img">
      <img :src="userInfo.head_pic" />
    </div>

    <div class="edit-text" @click="btnupname">
      <span>名字</span>
      <span>{{ myHomeInfo.nickname }}<i class="fas fa-angle-right"></i></span>
    </div>

    <div class="edit-textArea" @click="btnupjj">
      <span>简介</span>
      <span style="width: 80%;">{{ myHomeInfo.my_info }}<i class="fas fa-angle-right"></i></span>
    </div>
    <updatename
      :username="myHomeInfo.nickname"
      :my_info="myHomeInfo.my_info"
      @btnoutname="btnoutname"
      class="update-name"
      id="update-name"
    ></updatename>

    <updatejj
      :my_info="myHomeInfo.my_info"
      :username="myHomeInfo.nickname"
      @btnoutjj="btnoutjj"
      class="update-jj"
      id="update-jj"
    ></updatejj>
  </div>
</template>

<script>
import { myHome } from 'network/user'
import Updatename from './Updatename'
// import Updatephone from "./Updatephone";
// import Updatecon from "./Updatecon";
import Updatejj from './Updatejj'

export default {
  name: 'Editdatalist',
  components: {
    Updatename,
    // Updatephone,
    // Updatecon,
    Updatejj
  },
  data() {
    return {
      userInfo: null,
      myHomeInfo: {}
    }
  },
  props: {
    userlist: {
      type: Object
    }
  },
  created() {
    this.getMyHome()
  },
  methods: {
    getMyHome() {
      var that = this

      this.userInfo = JSON.parse(this.$store.state.userInfoJSON)
      myHome().then((res) => {
        that.myHomeInfo = res.data.info
      })
    },
    /*名称*/
    btnupname() {
      let updatename = document.getElementById('update-name')
      updatename.style.left = 0 + 'vh'
    },
    btnoutname() {
      let updatename = document.getElementById('update-name')
      updatename.style.left = 100 + 'vh'
    },

    /*电话*/
    btnupphone() {
      let updatephone = document.getElementById('update-phone')
      updatephone.style.left = 0 + 'vh'
    },
    btnoutphone() {
      let updatephone = document.getElementById('update-phone')
      updatephone.style.left = 100 + 'vh'
    },

    /*星座*/
    btnupcon() {
      let updatecon = document.getElementById('update-con')
      updatecon.style.left = 0 + 'vh'
    },
    btnoutcon() {
      let updatecon = document.getElementById('update-con')
      updatecon.style.left = 100 + 'vh'
    },

    /*简介*/
    btnupjj() {
      let updatejj = document.getElementById('update-jj')
      updatejj.style.left = 0 + 'vh'
    },
    btnoutjj() {
      let updatejj = document.getElementById('update-jj')
      updatejj.style.left = 100 + 'vh'
    }
  }
}
</script>

<style scoped>
.edit-img {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.edit-img img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}
.edit-text {
  margin: 0 15px;
  height: 50px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(220, 220, 220, 0.5);
}

.edit-textArea {
  margin: 0 15px;
  height: 150px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(220, 220, 220, 0.5);
}
.edit-text i {
  color: rgb(120, 120, 120);
  font-size: 12px;
  margin-left: 5px;
}
.update-name {
  position: fixed;
  height: 100vh;
  width: 100vh;
  background-color: #fff;
  z-index: 10;
  left: 100vh;
  right: 0;
  bottom: 0;
  transition: left 0.6s;
}

.update-phone {
  position: fixed;
  height: 100vh;
  width: 100vh;
  background-color: #fff;
  z-index: 10;
  left: 100vh;
  right: 0;
  bottom: 0;
  transition: left 0.6s;
}

.update-con {
  position: fixed;
  height: 100vh;
  width: 100vh;
  background-color: #fff;
  z-index: 10;
  left: 100vh;
  right: 0;
  bottom: 0;
  transition: left 0.6s;
}
.update-jj {
  position: fixed;
  height: 100vh;
  width: 100vh;
  background-color: #fff;
  z-index: 10;
  left: 100vh;
  right: 0;
  bottom: 0;
  transition: left 0.6s;
}
</style>
