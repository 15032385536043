<template>
  <div>
    <div class="edit-nav-name" :style="{ width: $store.state.screenWidth + 'px' }">
      <i class="fas fa-angle-left" @click="btnoutname"></i>
      <span>修改名字</span>
      <a @click="btneditUserInfo">保存</a>
    </div>
    <div class="edit-nav-input" :style="{ width: $store.state.screenWidth + 'px' }">
      <input v-model="username" :placeholder="username" :style="{ width: $store.state.screenWidth - 64 + 'px' }" />
    </div>
  </div>
</template>

<script>
import { updateUserInfo } from 'network/user'
export default {
  name: 'Updatename',
  props: {
    username: {
      type: String
    },
    my_info: {
      type: String
    }
  },
  data() {
    return {
      user_id: null,
      names: ''
    }
  },
  created() {
    this.user_id = window.sessionStorage.getItem('userId')
    this.name = this.username
  },
  methods: {
    btnoutname() {
      this.$emit('btnoutname')
    },
    btneditUserInfo() {
      updateUserInfo(this.my_info, this.username).then((res) => {
        console.log(res.state)
        this.$router.push('/usernote')
      })
    }
  }
}
</script>

<style scoped>
.edit-nav-name {
  width: 46%;
  height: 39px;
  box-shadow: 0 0 5px rgba(50, 50, 50, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
.edit-nav-name i {
  width: 10%;
  font-size: 20px;
}
.edit-nav-name span {
  font-size: 15px;
}
.edit-nav-name a {
  margin-right: 6%;
  background-color: #ff0000;
  padding: 7px 10px;
  border-radius: 10px;
  font-size: 12px;
  color: rgba(254, 254, 254, 0.9);
}
.edit-nav-input {
  margin: 20px 20px;
}
.edit-nav-input input {
  border: 0;
  background-color: rgba(220, 220, 220, 0.6);
  /*光标颜色*/
  caret-color: #1bc4fb;
  /*选中后*/
  outline: 0;
  color: #000;
  /*高度*/
  padding: 12px;
  width: 40%;
  border-radius: 12px;
  font-size: 13px;
}
.edit-nav-input input::placeholder {
  color: rgb(90, 90, 90);
  font-size: 14px;
}
</style>
